
.carousel-explore {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-cell {
  flex: 0 0 100%;
  height: 100%; 
  object-fit: cover;
  display: block;
  position: relative;
}

.carousel-explore img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.flickity-viewport {
  height: 100% !important;
}

.flickity-slider {
  display: flex; 
  gap: 0; 
}

.card-explore {
  display: flex;
  flex-direction: column;
  height: 301px;
  width: 236px;
  box-shadow: 1px 2px 16px 0px rgba(2, 13, 25, 0.10);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  flex: 0 0 236px;
}

.card-top {
  flex: 2 1 auto; 
  position: relative;
  overflow: hidden;
}

.card-top::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px; 
  background: linear-gradient(to top, rgba(60, 60, 60, 1), rgba(60, 60, 60, 0)); 
  z-index: 1;
}

.card-bottom {
  display: flex;
  align-items: center;
}

.flickity-page-dots {
  bottom: 20px !important;
  display: flex;
  justify-content: center; 
  gap: 8px; 
  margin-top: 10px; 
  align-items: center;
}

.flickity-page-dots .dot {
  width: 6px !important;
  height: 6px !important;
  background-color: var(--Black-Knight-300, #C6C6C6) !important;
  border-radius: 50%;  
  cursor: pointer;
  margin: 0 !important;
}

.flickity-page-dots .dot.is-selected {
  width: 8px !important;
  height: 8px !important;
  background-color: var(--Linen-100, #FFFEFD) !important;
  opacity: none !important;
}

.flickity-page-dots .flickity-page-dot {
  width: 6px !important;
  height: 6px !important;
  background-color: rgb(198 198 198 / 39%) !important;
  border-radius: 50%;  
  cursor: pointer;
  margin: 0 !important;
  border: none; 
  padding: 0; 
}

.flickity-page-dots .flickity-page-dot.is-selected {
  width: 8px !important;
  height: 8px !important;
  background-color: var(--Linen-100, #FFFEFD) !important;
  box-shadow: drop-shadow(1px 2px 8px #1C1B1A) !important;
  opacity: none !important;
  border: none; 
}

.flickity-prev-next-button {
  position: absolute;
  top: 56%;
  transform: translateY(-50%);
  border: none;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-prev-next-button svg {
  font-size: 30px;
  color: var(--Black-Knight-300, #C6C6C6);
}

.flickity-prev-next-button {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card-top:hover .flickity-prev-next-button {
  opacity: 1;
}

.dots-explore-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 30px; 
  left: 50%;
  transform: translateX(-50%);
}

.explorer-card-info-content {
  position: absolute;
  top: 12px;
}

.hover\:scale-105:hover {
  transform: scale(1.05);
}

.flickity-slider div {
  height: 100%;
}

/* style za date picker */
.flatpickr-calendar {
  left: 0 !important;
  top: 39px !important;
  width: auto !important;
  cursor: auto !important;
}


.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: var(--Black-Knight-600, #020D19);
  border: none;
}
.flatpickr-day.endRange {
  background: var(--Black-Knight-600, #020D19) !important;
}
.flatpickr-day.startOfWeek {
  border-radius: 50px 0 0 50px !important;
}

.flatpickr-day.endOfWeek {
  border-radius: 0 50px 50px 0 !important;
}

.custom-calendar {
  padding: 16px !important;
  align-items: flex-start !important;
  border-radius: 12px !important;
  background: #FFFEFD !important;
  box-shadow: 1px 2px 16px 0px rgba(2, 13, 25, 0.10) !important;
  border: none !important;
  left: -290px !important;
}

@media (max-width: 1024px) {
  body .custom-calendar {
      left: 0 !important;
      position: absolute !important;
      top: 60px !important;
  }
}

.numInputWrapper:hover,
.numInputWrapper input:hover,
.numInputWrapper .arrowUp:hover,
.numInputWrapper .arrowDown:hover {
  background-color: transparent !important;
  cursor: default !important; 
  border-color: transparent !important; 
}

.flatpickr-calendar {
  display: none; 
}

.flatpickr-calendar.open {
  display: inline; 
}

.flatpickr-day.today {
  border: 1px solid #959ea9 !important;
}

.flatpickr-day.today:hover {
  color: #000;
}

.flatpickr-current-month span.cur-month, .numInput.cur-year {
  color: var(--Black-Knight-600, #020D19) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 130% !important;
}

.flatpickr-current-month {
  padding: 0 !important;
}

.flatpickr-day.inRange {
  box-shadow: none !important;
  background-color: #E1D7CE !important;
  border: none !important;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  top: 10px !important;
  padding: 10px 20px;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #a8a8a8e6; 
}

.dayContainer + .dayContainer {
  box-shadow: none !important;
  justify-content: center  !important;

}

.dayContainer {
  padding: 0 19px;
  justify-content: center  !important;
}

span.flatpickr-weekday {
  flex: 0;
  margin: 0px; 
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  padding: 0 20px;
  gap: 0px;
}

.flatpickr-day.startRange,
.flatpickr-day.endRange:hover {
  background-color: var(--Black-Knight-600, #020D19) !important;
  color: #F2F2F2 !important;
}

.flatpickr-day {
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--Black-Knight-600, #020D19);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.numInputWrapper span {
  display: none !important;
}

.numInput.cur-year:hover {
  background-color: transparent;
}

.numInputWrapper:hover {
  background-color: transparent;
}

.flatpickr-current-month span.cur-month, .numInput.cur-year:hover {
  background-color: transparent !important;
}

.flatpickr-day.inRange, .flatpickr-day.startRange, .flatpickr-day.endRange {
  box-shadow: none !important;
}

.calendar-exp.flatpickr-calendar {
  left: 22px !important;
  top: 85px !important;
}

.flatpickr-calendar .availability {
  border-top: 1px solid #C6C6C6;
  padding-top: 12px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
}

.flatpickr-calendar .availability .btn {
  display: flex;
  width: 248.5px;
  height: 42px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Bright-Turquoise-600, #0FEED7);

  color: var(--Black-Knight-600, #020D19);
  text-align: center;
  font-family: Geist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.flatpickr-calendar .availability .btn:hover {
  background-color: #0bd1bd;
}

.calendar-exp.flatpickr-calendar .flatpickr-innerContainer {
  margin-bottom: 12px !important;
}

/* style za guests */
@media (max-width: 1024px) {
  #guests-modal {
    display: flex !important;
  }
}

.tab-btn {
  cursor: pointer;
}

.tab-btn.active {
  border-bottom: 1px solid var(--Black-Knight-600, #020D19);
  padding-bottom: 4px;
  color: #020D19; 
}

.hidden {
  display: none;
}

.tab-btn:focus {
  outline: none;
}

/* accordion content */
.accordion-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
  padding-top: 0; 
  padding-bottom: 0;
}

.accordion-content.expanded {
  max-height: 1000px; 
  padding-top: 1rem;   
  padding-bottom: 1rem;
}

.accordion-content.collapsed {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease-out;
}

/* property galeery */
.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.gallery-item img {
  width: 263px;
  height: 176px;
  object-fit: cover;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.gallery-item img:hover {
  transform: scale(1.02);
}


.gallery-item.hidden {
  display: none;
}

@media (max-width: 768px) {
  .gallery {
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0px;
  }

  .gallery-item img {
    width: 100%;
  }

  .gallery-item {
    scroll-snap-align: start;
    flex: none;
    width: 100%;
  }

  .gallery-item:not(:first-child) {
    display: none;
  }

  .gallery-item:first-child img {
    width: 100%;
    height: auto;
  }

  .grid-cols-4 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .see-all-btn {
    display: none;
  }

  .absolute.bottom-2.left-2 {
    display: block;
  }

  .overflow-ui {
    overflow-x: hidden !important;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  .gallery-item img {
    width: 100%;
    height: 176px;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
}

.overflow-ui {
  overflow-x: visible !important;
}

.flickity-enabled {
  position: absolute !important;
  left: 0 !important;
}

#limited-dots-carousel .flickity-page-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 140px; 
  margin: 0 auto;
  left: 30%;
}

#limited-dots-carousel .flickity-page-dots li {
  display: inline-block;
  margin: 0 2px; 
  flex-shrink: 0;
}

#limited-dots-carousel .flickity-page-dots li:not(:nth-child(-n+10)) {
  display: none; 
}

/* owl carousel  */
.scroll-left {
  animation: scroll-left 80s linear infinite;
}

.scroll-right {
  animation: scroll-right 80s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


@keyframes scroll-right {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

[data-carousel-properties-target="carousel"] {
  display: flex;
  gap: 24px; 
  width: max-content; 
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}

.carousel-card {
  flex-shrink: 0; 
  width: 302px;
  height: 240px;
  border-radius: 16px;
  background-size: cover;
  background-position: center;
}


.flickity-prev-next-button:focus,
.flickity-prev-next-button:focus-visible,
.flickity-prev-next-button:active {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.flickity-prev-next-button:focus,
.flickity-prev-next-button:focus-visible,
.flickity-prev-next-button:active,
.flickity-prev-next-button::-moz-focus-inner {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

button:focus,
button:focus-visible,
button::-moz-focus-inner {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
