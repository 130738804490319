.noUi-connects {
    height: 8px !important;
    flex-shrink: 0 !important;
    border-radius: 5px !important;
    background: var(--Black-Knight-200, #E4E4E4) !important;
}

.noUi-connect {
    background-color: #020D19 !important;
    height: 8px !important;
    border-radius: 5px !important;
}

.noUi-target {
    height: 8px !important;
    flex-shrink: 0 !important;
    border-radius: 5px !important;
    background: var(--Black-Knight-200, #E4E4E4) !important;
}

.noUi-handle {
    background: none !important;  
    border: none !important;     
    width: 0 !important;         
    height: 0 !important;        
    box-shadow: none !important; 
}


.noUi-handle svg {
    fill: var(--Black-Knight-600, #020D19);
    stroke-width: 3px;
    stroke: var(--Linen-100, #FFFEFD);
    filter: drop-shadow(1px 2px 16px rgba(2, 13, 25, 0.10));
    position: relative;
    top: -13px; 
    left: -30px;
}

.noUi-handle:after, .noUi-handle:before {
    content: none !important;
}

.price-inputs {
    display: flex;
    width: 350px;
    align-items: center;
    gap: 16px;
}

.overflow-hidden {
  overflow: hidden;
}

.checkbox {
    width: 24px !important;
    height: 24px !important;
    border-radius: 4px !important;
    border: 1px solid var(--Neutral-05, #C2C2C2) !important;
    background: var(--Shade-01, #FFF) !important;
}

.custom-checkbox {
    appearance: none; 
    width: 24px;
    height: 24px;
    background-color: #222;
    border-radius: 6px; 
    position: relative;
    cursor: pointer;
    outline: none;
}

.custom-checkbox:checked {
    background-color: #222 !important;
    border: none; 
    outline: none;
}

input.checkbox:focus {
    outline: none;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
.custom-checkbox:checked::before {
    content: "" !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    width: 16px !important;
    height: 16px !important;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><g clip-path="url(%23clip0_1007_13237)"><path d="M6.0001 10.7799L3.2201 7.9999L2.27344 8.93989L6.0001 12.6666L14.0001 4.66656L13.0601 3.72656L6.0001 10.7799Z" fill="white"/></g><defs><clipPath id="clip0_1007_13237"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>');
    background-size: 100% 100% !important;
    transform: translate(-50%, -50%) !important;
}
  