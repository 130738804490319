@import 'experiences';
@import 'filters';
@import 'flickity/dist/flickity.css';
@import 'nouislider/dist/nouislider.css';


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

@font-face {
  font-family: 'Geist';
  src: url('../fonts/Geist-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../fonts/Geist-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../fonts/Geist-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('../fonts/Geist-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: 'Geist', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  background-color: #FDF9F5;
  margin: 0 auto;
  display: grid;
  position: relative;
  padding-top: 45px;
}

nav {
  width: 100%;
  z-index: 9999;
  position: relative;
  background-color: #FFFEFD;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  --tw-shadow: 1px 2px 16px 0px rgba(2,13,25,0.10);
  --tw-shadow-colored: 1px 2px 16px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  position: absolute;
}


html {
  scroll-behavior: smooth; 
}

.menu-active {
  font-weight: 500;
  color: #020D19; 
}